<template>
  <v-container>
    <v-img src="./truck.jpeg" />
    <h1 v-if="!submitted && !error.show">Request your vehicle shipping qoute today!</h1>
        <v-form v-if="!submitted && !error.show"
          ref="form"
          v-model="valid"
          lazy-validation
          >
          <v-text-field
            v-model="fields.name"
            label="Name"
            required
            />
          <v-text-field
            v-model="fields.email"
            :rules="emailRules"
            label="E-mail"
            type="email"
            required
            />
          <v-text-field
            v-model="fields.phone"
            label="Phone number"
            type="tel"
            :rules="phoneRules"
            required
            />
          <v-text-field
            v-model="fields.year"
            label="Year"
            required
            />
          <v-text-field
            v-model="fields.make"
            label="Make"
            required
            />
          <v-text-field
            v-model="fields.model"
            label="Model"
            required
            />
          <v-text-field
            v-model="fields.pickup_date"
            label="Pickup date"
            required
            />
          <v-text-field
            v-model="fields.pickup_zipcode"
            label="Pickup ZIP code"
            required
            />
          <v-text-field
            v-model="fields.dropoff_zipcode"
            label="Drop off ZIP code"
            required
            />
          <v-btn
            :disabled="!valid"
            color="success"
            @click="validate"
            >
            Get qoute
          </v-btn>
        </v-form>
        <div v-if="submitted && !error.show">
          <h2>Thank you {{ fields.name }} for your request! </h2>
          <h3>We will {{ communication_methods }} you with your qoute shortly!</h3>
        </div>
        <div v-if="error.show">
          <h2>{{ error.message }} </h2>
          <v-btn color="error" @click="reload">Reload</v-btn>
        </div>
  </v-container>

</template>

<script>
export default {
  name: 'VehicleQoute',
  data: () => ({
    valid: true,
    submitted: false,
    fields: {
      name: '',
      email: '',
      phone: '',
      address: '',
      time_of_day: '',
      communication_method: ['email', 'text']
    },
    error: {
      message: '',
      show: false,
    },

    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid'
    ],

    phoneRules: [
      v => !!v || 'Phone is required',
      v => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(v) || 'Phone must be valid'
    ]

  }),
  computed: {
    user () {
      return this.$store.getters.user
    },
    communication_methods() {
      return this.fields.communication_method.join('/');
    }
  },

  methods: {
    reload() {
      location.reload();
    },
    async validate () {
      if (this.$refs.form.validate()) {
        try {
          const payload = {
            'AuthKey': '9364015b-4107-4ac9-8b63-f9003d3da9e0',
            'first_name':this.fields.name,
            'email': this.fields.email,
            'phone': this.fields.phone,
            "ship_date": this.fields.pickup_date,
            "transport_type": 1,
            "origin_postal_code": this.fields.pickup_zipcode,
            "destination_postal_code": this.fields.dropoff_zipcode,
            "vehicle_make": this.fields.make,
            "vehicle_model": this.fields.model,
            "vehicle_model_year": this.fields.year,
            "vehicle_inop": 0
          };

          const requestOptions = {
            method: "POST",
            body: JSON.stringify(payload)
          };
          const url ="https://api.batscrm.com/leads"
          fetch(url, requestOptions);

          this.submitted = true;

        } catch (ex) {
          this.error = {
            message: "Something went wront. Please try again later!",
            show: true
          };
          console.log(ex);
        }
      }
    },
  },
}
</script>
