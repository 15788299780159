<template>
  <v-app>
    <v-main>
      <v-img alt="Redner Transport Logo" src="./RednerTransport.svg" width="200" />
      <VehicleQoute />
    </v-main>
  </v-app>
</template>

<script>
import VehicleQoute from './VehicleQoute.vue'

export default {
  name: 'App',
  components: {
    VehicleQoute
  }
}
</script>

<style>
#app {
  text-align: center;
  margin-top: 60px;
}
</style>
