//import * as firebase_auth from "firebase/auth";

export default {
  state: () => ({
    user: null,
  }),
  mutations: {
    setUser (state, payload) {
      state.user = payload
    },
  },
  actions: {

    set_user({ commit }, user) {
      commit('setUser', user);
    },

  },
  getters: {
    user (state) {
      return state.user
    },
  }
}
