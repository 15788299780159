import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
//import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const firebase_config = {
  apiKey: "AIzaSyD8cm7qzoaZlLCEQADP5tBr0KKlDPoq_vU",
  authDomain: "redner-58e51.firebaseapp.com",
  projectId: "redner-58e51",
  storageBucket: "redner-58e51.appspot.com",
  messagingSenderId: "376408178901",
  appId: "1:376408178901:web:b3f566106e6719f179625c",
  measurementId: "G-S895RH6WVY"
};


const firebase_app = initializeApp(firebase_config);
const auth = getAuth(firebase_app);
const analytics = getAnalytics(firebase_app);
const firestore = getFirestore();

//connectFirestoreEmulator(firestore, 'localhost', 8081);

export default {
  install (vue_app) {
    vue_app.config.globalProperties.$firebase = firebase_app;
    vue_app.config.globalProperties.$firebase_auth = auth;
    vue_app.config.globalProperties.$firebase_analytics = analytics;
    vue_app.config.globalProperties.$firebase_firestore = firestore;

    const store = vue_app.config.globalProperties.$store;
    auth.onAuthStateChanged(user =>  {
      store.dispatch('set_user', null);
      if (user) {
        store.dispatch('set_user', user);
      } else {
        signInAnonymously(auth)
          .catch((error) => {
            console.error(error);
          });
      }
    });
  }
}
